import React, { Component } from "react";
import "./styles/main.scss";

import { getTranslations } from "./translations";

import { Navbar } from "./sites/navbar";
import Home from "./sites/home";
import About from "./sites/about";
import Slogan from "./sites/slogan";
import Illustration from "./sites/illustration";
import Media from "./sites/media";
import Emphasis from "./sites/emphasis";
import StudioA from "./sites/studio-a";
import References from "./sites/references";
import Projects from "./sites/projects";
import Contact from "./sites/contact";
import Footer from "./sites/footer";
import { Privacy } from "./sites/privacy";
import { Impress } from "./sites/impress";

import { Cookie } from "./sites/cookienotice";

export default class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookie: {},
      impress: false,
      privacy: false,
      newTexts: false,
      cookieNotice: false,
      cookieAccept: false,
    };

    this.closePopups = this.closePopups.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  async componentWillMount() {
    await getTranslations();
    this.setState({ newTexts: true });
  }

  componentDidMount() {
    this.getCookie();
    if (!this.state.impress && !this.state.privacy) {
      document.body.style.overflowY = "visible";
    } else {
      document.body.style.overflowY = "hidden";
    }

    window.addEventListener("beforeunload", (ev) => {
      if (!this.state.cookieAccept && !this.state.cookie.save) {
        console.log("foo");
        document.cookie = "language=";
        document.cookie = "mode=";
      }
    });
  }

  closePopups() {
    this.setState({ impress: false, privacy: false });
    document.body.style.overflowY = "visible";
  }

  openModal(state) {
    document.body.style.overflowY = "hidden";
    this.setState({ [state]: true });
  }

  getCookie() {
    let cookies = document.cookie.split(`;`);
    let cookieObj = {};
    if (cookies.length > 1) {
      cookies.forEach((cookie) => {
        let [key, value] = cookie.split("=");
        cookieObj[key.replace(/\s/g, "")] = value.replace(/\s/g, "");
      });
    }
    console.log(cookieObj);
    if (!cookieObj.mode && !cookieObj.language) this.setState({ cookieNotice: true });
    if (!cookieObj.mode) {
      document.cookie = "mode=dark";
      cookieObj.mode = "dark";
    }
    if (!cookieObj.language) {
      document.cookie = "language=de";
      cookieObj.language = "de";
    }
    this.setState({ cookie: cookieObj });

    const root = document.documentElement;
    root.style.setProperty("--text-color", cookieObj.mode === "dark" ? "#fff" : "#000");
    root.style.setProperty("--background-color", cookieObj.mode === "dark" ? "#000000" : "#ffffff");
    root.style.setProperty("--navbar-color", cookieObj.mode === "dark" ? "rgba(0,0,0,0.5)" : "rgba(255,255,255,0.7)");

    root.style.setProperty("--color-primary", "#5ae0c0");
    root.style.setProperty("--transition-speed", "0.3s");
    root.style.setProperty("--inner-height", `${window.innerHeight * 0.01}px`);
  }

  handleLanguageChange(e) {
    document.cookie = "language=" + e.target.innerText.toLowerCase();
    this.getCookie();
  }

  handleModeChange(e) {
    document.cookie = this.state.cookie.mode === "dark" ? "mode=light" : "mode=dark";
    this.getCookie();
  }

  render() {
    return this.state.newTexts ? (
      <div className="main">
        {this.state.cookieNotice ? (
          <Cookie
            language={this.state.cookie.language}
            closeNote={() => {
              this.setState({ cookieNotice: false, cookieAccept: true });
              document.cookie = "save=true";
            }}
            disableCookies={() => {
              this.setState({ cookieNotice: false, cookieAccept: false });
            }}
          />
        ) : (
          false
        )}
        <Navbar
          languageChange={this.handleLanguageChange.bind(this)}
          language={this.state.cookie.language}
          mode={this.state.cookie.mode}
          modeChange={this.handleModeChange.bind(this)}
          closePopup={this.closePopups}
        />
        <section id="home">
          <Home language={this.state.cookie.language} />
        </section>
        <section id="about">
          <About language={this.state.cookie.language} mode={this.state.cookie.mode} />
        </section>
        <section>
          <Slogan language={this.state.cookie.language} />
        </section>
        <section id="illustration">
          <Illustration />
        </section>
        <section id="media">
          <Media language={this.state.cookie.language} />
        </section>
        <section id="emphasis">
          <Emphasis language={this.state.cookie.language} />
        </section>
        <section id="studioa">
          <StudioA language={this.state.cookie.language} />
        </section>
        <section id="references">
          <References language={this.state.cookie.language} />
        </section>
        <section id="ref">
          <Projects language={this.state.cookie.language} />
        </section>
        <section id="contact">
          <Contact language={this.state.cookie.language} openModal={this.openModal} />
        </section>
        <section id="footer">
          <Footer language={this.state.cookie.language} mode={this.state.cookie.mode} openModal={this.openModal} />
        </section>
        <Privacy language={this.state.cookie.language} active={this.state.privacy} close={this.closePopups} />
        <Impress language={this.state.cookie.language} active={this.state.impress} close={this.closePopups} />
      </div>
    ) : (
      <></>
    );
  }
}
