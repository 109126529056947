import { Component } from "react";

import { texts } from "./../translations";

import { ArrowDown } from "../inserts/arrow/arrow";

export default class Media extends Component {
  render() {
    return (
      <div className="Media container">
        <p className="title">{texts.media.title[this.props.language]}</p>
        <div className="divider"></div>
        <div className="row">
          <div className="col-lg-9 p-0">
            <p className="text-medium"> {texts.media.text_1[this.props.language]}</p>
            <p className="text-small">{texts.media.text_2[this.props.language]}</p>
          </div>
          <div className="col-lg-3 right">
            <p className="text-small">{texts.media.conception[this.props.language]}</p>
            <ArrowDown />
            <p className="text-small">{texts.media.planning[this.props.language]}</p>
            <ArrowDown />
            <p className="text-small">{texts.media.realization[this.props.language]}</p>
          </div>
        </div>
      </div>
    );
  }
}
