import "./brands.scss";

const brandBar = () => {
  return (
    <div className="brands-wrapper">
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand1.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand2.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand3.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand4.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand5.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand6.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand7.png"} alt="" />
      <img src={process.env.PUBLIC_URL + "/images/brands/Brand8.png"} alt="" />
    </div>
  );
};

export default brandBar;
