import { Component } from "react";

import { ReactComponent as Illu } from "./../images/210531-blickfang-illustration-bereich.svg";

export default class Illustration extends Component {
  render() {
    return (
      <div className="illustration">
        <Illu />
      </div>
    );
  }
}
