import { Component } from "react";

import { texts } from "../translations";

import { ArrowUp } from "./../inserts/arrow/arrow";

import Brands from "./../inserts/brands";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.projects = this.projects.bind(this);
    this.state = {
      activeProject: 0,
    };
    this.handleProjectChange = this.handleProjectChange.bind(this);
  }

  handleProjectChange(e) {
    this.setState({ activeProject: this.state.activeProject != e.currentTarget.id ? parseInt(e.currentTarget.id) : 0 });
  }

  projects(min, max) {
    let projects = [];

    for (let i = min; i <= max; i++) {
      projects.push(
        <>
          <div
            className={`project row ${this.state.activeProject === i ? "active" : ""}`}
            id={i}
            onClick={this.handleProjectChange}
          >
            <div className="col-10" id={i}>
              <p className="text-medium" id={i}>
                {texts.projects["project_" + i].title[this.props.language]}
              </p>
              <p className="text-small" id={i}>
                {texts.projects["project_" + i].body[this.props.language]}
              </p>
            </div>
            <div className="col-2" id={i}>
              <ArrowUp className={`bi `} height="100%" id={i} />
            </div>
          </div>
          <div className="divider"></div>
        </>
      );
    }

    return projects;
  }

  render() {
    return (
      <div className="projects">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 p-0">{this.projects(1, 5)}</div>
            <div className="col-lg-6 p-0">{this.projects(6, 10)}</div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <Brands />
          </div>
        </div>
      </div>
    );
  }
}
