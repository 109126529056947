import { Component } from "react";
import { ReactSVG } from "react-svg";

import { texts } from "../translations";

export default class Emphasis extends Component {
  render() {
    return (
      <div className="emphasis container">
        <div className="row">
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-Hologram.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.hologram[this.props.language]}</p>
            </div>
          </div>
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-Interactive.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.interactive[this.props.language]}</p>
            </div>
          </div>
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-Film.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.film[this.props.language]}</p>
            </div>
          </div>
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-Projection.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.projection[this.props.language]}</p>
            </div>
          </div>
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-VR.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.vr[this.props.language]}</p>
            </div>
          </div>
          <div className="col-lg-2 p-0 emphase">
            <ReactSVG src={process.env.PUBLIC_URL + "/images/icons/Icon-AR.svg"} />

            <div className="emphasisText">
              <p className="text-small"> {texts.emphasis.ar[this.props.language]}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
