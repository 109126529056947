export const Cookie = (props) => {
  return (
    <div className="cookie-notice" style={style}>
      <p style={{ width: "80%", fontSize: "1.2rem", float: "left" }}>
        {props.language === "de" ? cookieNotice.de : cookieNotice.en}
      </p>
      <div className="buttons" style={{ display: "inline-flex", flexDirection: "row" }}>
        <button
          className="btn btn-success"
          style={{ margin: "2px" }}
          onClick={() => {
            props.closeNote();
          }}
        >
          {props.language === "de" ? "Akzeptieren" : "Accept"}
        </button>
        <button
          className="btn btn-danger"
          style={{ margin: "2px" }}
          onClick={() => {
            props.disableCookies();
          }}
        >
          {props.language === "de" ? "Ablehnen" : "Decline"}
        </button>
      </div>
    </div>
  );
};

// export default Cookie;

const style = {
  width: "100vw",
  backgroundColor: "rgba(0,0,0,0.8)",
  position: "fixed",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 999,
  padding: "2%",
  color: "#fff",
};

const cookieNotice = {
  de: "Wir benutzen Cookies zur barrierearmen Darstellung der Website. Die Cookies werden nicht von uns gespeichert oder an Dritte weitergegeben.",
  en: "We use cookies for a barrier-free presentation of the website. The cookies are not stored by us or passed on to third parties.",
};
