export default function getTextInLanguage(text, language) {
  return texts[text][language];
}

export async function getTranslations() {
  let newData = await fetch("website_translations.json").then((res) => res.json());
  texts = { ...newData };
  return;
}

export let texts = {};
