import React, { Component } from "react";
import "./../styles/mixins/navbar.scss";

import { texts } from "./../translations";

import { CircleCross } from "../inserts/cross";
import { CircleBurger } from "../inserts/burger";

export class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false, languageDropdown: false };
    this.closeAll = this.closeAll.bind(this);
  }

  toggleNav(state) {
    this.setState(
      (prevState) => ({
        [state]: !prevState[state],
      }),
      () => {
        if (state === "open" && this.state.open) {
          document.body.style.overflowY = "hidden";
        } else {
          document.body.style.overflowY = "visible";
        }
      }
    );
  }

  closeAll() {
    if (window.matchMedia("(max-width: 700px)").matches) this.toggleNav("open");
    this.props.closePopup();
  }

  render() {
    return (
      <div className="navbar">
        <a className="navbar-brand" href="#home" onClick={this.closeAll}>
          <img
            src={
              this.props.mode === "dark"
                ? process.env.PUBLIC_URL + "/images/logos/Logo-Icon-white.png"
                : process.env.PUBLIC_URL + "/images/logos/Logo-Icon-black.png"
            }
            alt=""
          />
        </a>
        <div className="collapse-button">
          {this.state.open ? (
            <CircleCross
              onClick={() => {
                this.toggleNav("open");
              }}
            />
          ) : (
            <CircleBurger
              onClick={() => {
                this.toggleNav("open");
              }}
            />
          )}
        </div>
        <ul className={`navbar-nav ${this.state.open ? "open" : ""}`}>
          <li className="nav-item">
            <a className="nav-link" href="#about" onClick={this.closeAll}>
              {texts.navbar.about[this.props.language]}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#media" onClick={this.closeAll}>
              {texts.navbar.media[this.props.language]}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#references" onClick={this.closeAll}>
              {texts.navbar.references[this.props.language]}
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact" onClick={this.closeAll}>
              {texts.navbar.contact[this.props.language]}
            </a>
          </li>
          <li
            className="nav-item language bottom"
            onClick={() => {
              this.toggleNav("languageDropdown");
            }}
          >
            <a className="nav-link">{this.props.language === "de" ? "DE" : "EN"}</a>
            <span className={`dropdown-option ${this.state.languageDropdown ? "open" : ""}`} onClick={this.props.languageChange}>
              {this.props.language === "de" ? "EN" : "DE"}
            </span>
          </li>
          <li className="nav-item bottom right">
            <input
              type="checkbox"
              className="mode-switch"
              id="customSwitch1"
              checked={this.props.mode === "dark" ? true : false}
              onChange={this.props.modeChange}
            />
            <label className="mode-switch-label" htmlFor="customSwitch1">
              {this.props.mode === "dark" ? texts.navbar.dark[this.props.language] : texts.navbar.light[this.props.language]}
            </label>
            {/* </div> */}
          </li>
        </ul>
      </div>
    );
  }
}
