import axios from "axios";

export default function sendMessage(data) {
  console.log(data);
  axios({
    method: "post",
    url: "https://www.blickfanggmbh.com/index.php",
    headers: { "content-type": "application/json" },
    data: data,
  }).then((res) => {
    console.log(res);
  });
}
