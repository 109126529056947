import { ReactComponent as SVG } from "./../images/icons/Blickfang_Icon-burger.svg";
import "./burger.scss";

export const CircleBurger = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <SVG className={"circle-burger "} style={{ width: props.length, height: props.length, fill: props.color }} />
    </div>
  );
};
