import { ReactComponent as CircleArrow } from "./../../images/icons/Blickfang_Icon-circle-arrow-black.svg";

import "./circleArrow.scss";

export const CircleArrowDown = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <CircleArrow className={"circle-arrow "} style={{ width: props.length, height: props.length, fill: props.color }} />
    </div>
  );
};

export const CircleArrowUp = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <CircleArrow
        className={"circle-arrow circle-arrow-up "}
        style={{ width: props.length, height: props.length, fill: props.color }}
      />
    </div>
  );
};

export const CircleArrowLeft = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <CircleArrow
        className={"circle-arrow circle-arrow-left "}
        style={{ width: props.length, height: props.length, fill: props.color }}
      />
    </div>
  );
};

export const CircleArrowRight = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <CircleArrow
        className={"circle-arrow circle-arrow-right "}
        style={{ width: props.length, height: props.length, fill: props.color }}
      />
    </div>
  );
};
