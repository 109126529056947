import { Component } from "react";

import { texts } from "../translations";

export default class Slogan extends Component {
  scrollDown() {
    const contact = document.getElementById("contact");
    window.scroll({ top: contact.offsetTop, left: 0, behavior: "smooth" });
  }
  render() {
    return (
      <div className="slogan container">
        <div
          className="row"
          onClick={() => {
            this.scrollDown();
          }}
        >
          <div className="col-lg-9 p-0">
            <p className="text-big divider-animation"> {texts.slogan_2[this.props.language]}</p>
          </div>
          <div className="col-lg-3 contact">
            <p className="text-small">{texts.slogan_2.get_in_contact[this.props.language]}</p>
            <i className="bi bi-envelope" />
          </div>
        </div>
      </div>
    );
  }
}
