import { Component } from "react";

import { texts } from "./../translations";

import { ReactSVG } from "react-svg";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="row">
          <div className="col-lg-4">
            <div className="logo-wrapper">
              <ReactSVG src={process.env.PUBLIC_URL + "/images/logos/Logo-Text-Long-addition.svg"} />
            </div>
          </div>

          <div className="col-lg-2 text-small">
            <p>{texts.contact.address[this.props.language]}</p>
          </div>
          <div className="col-lg-2 text-small">
            <p className="text-small phone">
              <a className="action-text" href="tel:004922137998484">
                {texts.contact.phone[this.props.language]}
              </a>
            </p>
            <p className="text-small email">
              <a className="action-text" href="mailto:info@blickfanggmbh.de">
                {texts.contact.email[this.props.language]}
              </a>
            </p>
          </div>
          <div className="col-lg-2 text-small">
            <a href="#about"> {texts.navbar.about[this.props.language]}</a>
            <a href="#media"> {texts.navbar.media[this.props.language]}</a>
            <a href="#references"> {texts.navbar.references[this.props.language]}</a>
            <a href="#contact"> {texts.navbar.contact[this.props.language]}</a>
          </div>
          <div className="col-lg-2 text-small">
            <a
              onClick={() => {
                this.props.openModal("impress");
              }}
            >
              {texts.footer.impress[this.props.language]}
            </a>
            <a
              onClick={() => {
                this.props.openModal("privacy");
              }}
            >
              {texts.footer.privacy[this.props.language]}
            </a>
            <p className="pt-5">&copy; Blickfang GmbH</p>
          </div>
        </div>
      </div>
    );
  }
}
