import { Component } from "react";

import { texts } from "./../translations";

import sendMessage from "./../contactApi";

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agree: false,
      name: "",
      tel: "",
      email: "",
      message: "",
      notification: { active: "", status: "success", text: "" },
    };

    this.changeValue = this.changeValue.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  toggleAgree() {
    this.setState((prevState) => ({
      agree: !prevState.agree,
    }));
  }

  sendMessage() {
    let valid = [];

    validation(this, "name", "");
    validation(this, "email", "");
    validation(this, "message", "");
    validation(this, "agree", false);

    function validation(context, state, checkValue) {
      if (context.state[state] === checkValue) {
        valid.push(false);
        context.notification("error", [
          state != "agree"
            ? texts.contact.formular.error_handling.input[context.props.language] +
              texts.contact.formular[state][context.props.language]
            : texts.contact.formular.error_handling.privacy[context.props.language],
        ]);
      } else {
        valid.push(true);
      }
    }

    if (valid.every((value) => value === true)) {
      sendMessage({ name: this.state.name, tel: this.state.tel, email: this.state.email, message: this.state.message });
      this.notification("success", texts.contact.formular.notification.success[this.props.language]);
    }
  }

  notification(status, msg) {
    this.setState({ notification: { active: "active", status: status, message: msg } }, () => {
      setTimeout(() => {
        this.setState({ notification: { active: "", status: status, message: msg } });
      }, 3000);
    });
  }

  changeValue(e) {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value });
  }

  render() {
    return (
      <div className="contact container">
        <p className="title">{texts.contact.title[this.props.language]}</p>
        <div className="divider"></div>
        <div className="row">
          <div className="col-lg-4 p-0">
            <p className="text-small"> {texts.contact.address[this.props.language]}</p>
            <br />
            <p className="text-small phone">
              <a className="action-text" href="tel:004922137998484">
                {texts.contact.phone[this.props.language]}
              </a>
            </p>
            <p className="text-small email">
              <a className="action-text" href="mailto:info@blickfanggmbh.de">
                {texts.contact.email[this.props.language]}
              </a>
            </p>
          </div>

          <div className="frame col-lg-8">
            {/* <iframe
              src="https://www.google.com/maps/d/u/0/embed?mid=1sih3y_qoHDzX-nFlmBlEZgW6IjXyjK4h"
              width="100%"
              className=" maps"
            ></iframe> */}
            <img
              src={process.env.PUBLIC_URL + "/images/location.jfif"}
              alt="map"
              className="map"
              onClick={() =>
                window.open(
                  "https://www.google.de/maps/place/Gereonswall+99-101,+50670+K%C3%B6ln/@50.944919,6.9439071,19z/data=!3m1!4b1!4m5!3m4!1s0x47bf2509d060ae47:0xbe4e5a7ab4f718f8!8m2!3d50.9449182!4d6.9450283?hl=de",
                  "_blank"
                )
              }
            />
          </div>
          {/* <div
            className="col-lg-8 maps "
            onClick={() => {
              window.open(
                "https://www.google.de/maps/place/Gereonswall+103A,+50670+K%C3%B6ln/@50.9447505,6.9446466,20.75z/data=!4m5!3m4!1s0x47bf2509d0c5a49d:0x443417978a31b876!8m2!3d50.9448467!4d6.9448562?hl=de",
                "_blank"
              );
            }}
          ></div> */}
        </div>
        <div className="row" style={{ marginTop: "1rem" }}>
          <div className="col-lg-4 p-0">
            <p className="text-small"> {texts.contact.text_1[this.props.language]}</p>
            <br />
            <p className="text-small phone"> {texts.contact.text_2[this.props.language]}</p>
          </div>
          <div className="col-lg-8 formular p-0">
            <form>
              <fieldset>
                <input
                  className="text-small"
                  type="text"
                  id="name"
                  name="name"
                  required
                  onChange={this.changeValue}
                  value={this.state.name}
                />
                <label className="text-small" for="name">
                  {texts.contact.formular.name[this.props.language]} *
                </label>
              </fieldset>
              <fieldset>
                <input
                  className="text-small"
                  type="tel"
                  pattern="^[0-9-+\s()]*$"
                  id="tel"
                  name="tel"
                  onChange={this.changeValue}
                  value={this.state.tel}
                  required
                />
                <label className="text-small" for="tel">
                  {texts.contact.formular.tel[this.props.language]}
                </label>
              </fieldset>
              <fieldset>
                <input
                  className="text-small"
                  type="email"
                  id="email"
                  name="email"
                  required
                  onChange={this.changeValue}
                  value={this.state.email}
                />
                <label className="text-small" for="email">
                  {texts.contact.formular.email[this.props.language]} *
                </label>
              </fieldset>
              <fieldset>
                <textarea
                  className="text-small"
                  type="textarea"
                  id="message"
                  name="message"
                  required
                  onChange={this.changeValue}
                  value={this.state.message}
                />
                <label className="text-small" for="message">
                  {texts.contact.formular.message[this.props.language]} *
                </label>
              </fieldset>
            </form>
            <div className="col-12 p-0">
              <p className="col-12 required">* {texts.contact.formular.required[this.props.language]}</p>
              <p className="agree col-1 p-0">
                <input className="checkbox" type="checkbox" checked={this.state.agree} />
                <label onClick={this.toggleAgree.bind(this)}></label>
              </p>

              <p className="information col-11">
                {texts.contact.formular.data_accept[this.props.language].replace(":", ":%").split("%")[0]}{" "}
                <a
                  onClick={() => {
                    this.props.openModal("privacy");
                  }}
                >
                  {texts.contact.formular.data_accept[this.props.language].replace(":", ":%").split("%")[1]}
                </a>
              </p>
            </div>

            <div className="col-lg-5 col-sm-12 row p-0 float-right confirm">
              <p className="text-small divider-animation" onClick={this.sendMessage}>
                {texts.contact.formular.send[this.props.language]} <i className="bi bi-envelope" />
              </p>
              <p className={`note ${this.state.notification.active} ${this.state.notification.status} `}>
                {this.state.notification.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
