import { Component } from "react";

// import { ReactComponent as Logo } from "./../images/Logo-Text-Long-white.svg";
import { CircleArrowDown } from "./../inserts/circle arrow/circleArrow";
import { texts } from "./../translations";

export default class Home extends Component {
  render() {
    return (
      <div
        className="Home "
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/Background.jpg')`, height: window.innerHeight }}
      >
        <div className="row">
          <img src={process.env.PUBLIC_URL + "/images/logos/Logo-Text-Long-white.svg"} className="logo-v1 col-12" />
          <p className="text-big text-center col-12 white"> {texts.slogan[this.props.language]}</p>
          <a href="#about">
            <CircleArrowDown length="50px" color="#fff" />
          </a>
        </div>
      </div>
    );
  }
}
