import { Component } from "react";

import { Divider } from "../divider";

import { texts } from "./../translations";

export default class About extends Component {
  render() {
    return (
      <div className="about container">
        <div className="row">
          <div className="col-lg-9 p-0">
            <p className="title">{texts.about.title[this.props.language]}</p>
            <Divider />
            <p className="text-medium"> {texts.about.text_1[this.props.language]}</p>
            <p className="text-small">{texts.about.text_2[this.props.language]}</p>
            <p className="text-small">{texts.about.text_3[this.props.language]}</p>
          </div>
          <div className="col-lg-3 image-holder">
            <img
              src={
                this.props.language === "de"
                  ? this.props.mode === "dark"
                    ? process.env.PUBLIC_URL + "/images/Blickfang_Icon_light-seit-20-jahren.png"
                    : process.env.PUBLIC_URL + "/images/Blickfang_Icon_dark-seit-20-jahren.png"
                  : this.props.mode === "dark"
                  ? process.env.PUBLIC_URL + "/images/Blickfang_Icon_light-since-20-years.png"
                  : process.env.PUBLIC_URL + "/images/Blickfang_Icon_dark-since-20-years.png"
              }
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}
