import "./../styles/mixins/popups.scss";

export const Impress = (props) => {
  return (
    <div className={`impress popup ${props.active ? "active" : ""}`}>
      <i
        className="bi bi-x-circle gallery-control"
        style={{ position: "absolute", top: 0, right: "5%", fontSize: "2rem" }}
        onClick={() => {
          props.close();
        }}
      />
      <h1 className="title">
        {texts.title[props.language]}
        <div className="divider"></div>
      </h1>
      {sections(props.language)}
    </div>
  );
};

const texts = {
  title: { de: "Impressum", en: "Imprint" },
  section1: {
    name: { de: "Name und Anschrift des Verantwortlichen", en: "Name and Adress" },
    title: { de: "Angaben gem\u00E4\u00DF \u00A7 5 TMG", en: "Angaben gem\u00E4\u00DF \u00A7 5 TMG" },
    body: {
      de: `Blickfang GmbH
          Gereonswall 103a 
          50670 Köln 
          Telefon: 0049 221 37998484 
          Email: info@blickfanggmbh.de 
          Website: blickfanggmbh.de
		      Vertreten durch: Daniel Augusiak
			  
		  Design von nachmorgen.de
         
          Handelsregister: HRB 50484
          Registergericht: Amtsgericht Köln
          Umsatzsteuer-Identifikationsnummer gem\u00E4\u00DF \u00A7 27 a Umsatzsteuergesetz: DE228802255 `,

      //

      en: `Blickfang GmbH
          Daniel Augusiak 
          Gereonswall 103a 
          50670 Köln 
          Telefon: 0049 221 37998484 
          Email: info@blickfanggmbh.de 
          Website: blickfanggmbh.de   
		  
		  
		  Design von nachmorgen.de       
          
          Handelsregister: HRB 50484
          Registergericht: Amtsgericht Köln
          Umsatzsteuer-Identifikationsnummer gem\u00E4\u00DF \u00A7 27 a Umsatzsteuergesetz: DE228802255 `,
    },
  },
};

function sections(language) {
  let sections = [];
  for (const key in texts) {
    if (key.includes("section")) {
      sections.push(
        <>
          <h2 className="text-medium">{texts[key].name[language]}</h2>
          {texts[key].title ? <h3 className="text-medium">{texts[key].title[language]}</h3> : false}
          <p className="text-small">{texts[key].body[language]}</p>
        </>
      );
    }
  }
  return sections;
}
