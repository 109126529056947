import React, { Component } from "react";

import { texts } from "./../translations";

export default class StudioA extends Component {
  render() {
    return (
      <div className="studio-a container">
        <div className="row">
          <div className="col-lg-9 p-0">
            <p className="text-medium">
              {texts.studio_a.text[this.props.language]}
              <span style={{ fontWeight: "bold" }}>{texts.studio_a.highlight[this.props.language]}</span>
            </p>
          </div>
          <div className="col-lg-3 contact" onClick={() => window.open("http://www.studioagmbh.de", "_blank")}>
            <p className="text-small divider-animation">
              {texts.studio_a.website[this.props.language]} <i className="bi bi-envelope" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
