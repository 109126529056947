import "./../styles/mixins/popups.scss";

export const Privacy = (props) => {
  return (
    <div className={`privacy popup ${props.active ? "active" : ""}`}>
      <i
        className="bi bi-x-circle gallery-control"
        style={{ position: "absolute", top: 0, right: "5%", fontSize: "2rem" }}
        onClick={() => {
          props.close();
        }}
      />
      <h1 className="title">
        {texts.title[props.language]}
        <div className="divider"></div>
      </h1>
      {sections(props.language)}
    </div>
  );
};

const texts = {
  title: { de: "Datenschutzerklärung", en: "Data privacy statement" },
  section1: {
    name: { de: "Name und Anschrift des Verantwortlichen", en: "Name and Adress" },
    body: {
      de: `Blickfang GmbH
          Daniel Augusiak 
          Gereonswall 103a 
          50670 Köln 
          Telefon: 0049 221 37998484 
          Email: info@blickfanggmbh.de 
          Website: blickfanggmbh.de`,
      en: `Blickfang GmbH
          Daniel Augusiak 
          Gereonswall 103a 
          50670 Köln 
          Telefon: 0049 221 37998484 
          Email: info@blickfanggmbh.de 
          Website: blickfanggmbh.de`,
    },
  },
  section2: {
    name: { de: "Umgang mit persönlichen Daten", en: "Personal data handling" },
    body: {
      de: `Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. So behandeln wir Ihre personenbezogenen Daten selbstverständlich stets entsprechend der gesetzlichen Datenschutzvorschriften. Nachfolgend möchten wir Sie über die Verarbeitung von personenbezogenen Daten informieren.`,
      en: `We take the protection of your personal data very seriously. It goes without saying that we always treat your personal data in accordance with the statutory data protection regulations. In the following, we would like to inform you about the processing of personal data.`,
    },
  },
  section3: {
    name: { de: "Kontaktformular und E-Mail-Kontakt", en: "contact form and e-mail contact" },
    body: {
      de: `Unsere Internetseite beinhaltet Kontaktformulare, über die Sie mit der Blickfang GmbH in Kontakt treten können. Hierzu speichern wir Ihre Eingaben (Nachricht und E-Mail-Adresse) sowie die Nutzungsdaten (zum Beispiel Datum, Uhrzeit) der Anfrage. Alternativ ist eine Kontaktaufnahme vie E-mail möglich. In diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert. Die Daten werden für die Verarbeitung der Konversation beziehungsweise Anfrage verwendet, es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte.
Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen.`,
      en: `Our website contains contact forms that you can use to get in touch with Blickfang GmbH. For this purpose, we store your input (message and e-mail address) as well as the usage data (e.g. date, time) of the request. Alternatively, it is possible to contact us by e-mail. In this case, the users personal data transmitted with the e-mail will be stored. The data is used for processing the conversation or enquiry, and is not passed on to third parties in this context.
The other personal data processed during the sending process are used to prevent misuse of the contact form and to ensure the security of our information technology systems.`,
    },
  },
  section4: {
    name: { de: "Cookies", en: "Cookies" },
    body: {
      de: `Diese Seite verwendet „Cookies“ (exakt: ein Webanalyse-Cookie). Cookies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Internetpräsenz ermöglicht und Sie bei Ihrem nächsten Besuch automatisch wiedererkennen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihres Browsers verhindern. Dies kann gegebenenfalls dazu führen, dass Sie nicht alle Angebote vollumfänglich nutzen können.`,
      en: `This site uses "cookies" (exactly: a web analysis cookie). Cookies are text files which are stored on your computer and which enable an analysis of the use of the website and automatically recognise you on your next visit. You can prevent the installation of cookies by setting your browser accordingly. This may mean that you cannot make full use of all offers.`,
    },
  },
  section5: {
    name: { de: "Weitergabe der Daten", en: "passing on of data" },
    body: {
      de: `Eine Weitergabe an Dritte, zu kommerziellen oder nicht-kommerziellen Zwecken, findet ohne Ihre ausdrückliche Zustimmung ausdrücklich nicht statt.`,
      en: `Data will not be passed on to third parties for commercial or non-commercial purposes without your express consent.`,
    },
  },
  section6: {
    name: { de: "Haftung für eigene Inhalte", en: "liability for own contents" },
    body: {
      de: `Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.`,
      en: `The contents of these pages were created with the greatest care. However, we cannot assume any liability for the correctness, completeness and topicality of the contents.`,
    },
  },

  section7: {
    name: { de: "Haftung für Links (Inhalte fremder Anbieter)", en: "liability for links (contents of external providers)" },
    body: {
      de: `Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.`,
      en: `A distinction must be made between our own content and cross-references ("links") to content provided by other providers. We have no influence on their content; the respective provider or operator of the pages is always responsible for the content of the linked pages.`,
    },
  },
  section8: {
    name: { de: "Änderung unserer Datenschutzbestimmungen", en: "change of our data protection regulations" },
    body: {
      de: `Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, zum Beispiel bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.`,
      en: `We reserve the right to amend this data protection declaration from time to time so that it always complies with current legal requirements or in order to implement changes to our services in the data protection declaration, for example when introducing new services. The new data protection statement will then apply to your next visit.`,
    },
  },
  section9: {
    name: { de: "Fragen", en: "questions" },
    body: {
      de: `Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben, können Sie sich jederzeit an uns wenden. Wir stehen im Falle von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung.`,
      en: `If you have any questions regarding the processing of your personal data, you can contact us at any time. We are available in the event of requests for information, applications or complaints.`,
    },
  },
};

function sections(language) {
  let sections = [];
  for (const key in texts) {
    if (key.includes("section")) {
      sections.push(
        <>
          <h2 className="text-medium">{texts[key].name[language]}</h2>
          {texts[key].title ? <h3 className="text-medium">{texts[key].title[language]}</h3> : false}
          <p className="text-small">{texts[key].body[language]}</p>
        </>
      );
    }
  }
  return sections;
}
