import { ReactComponent as Arrow } from "./../../images/icons/blickfang_Icon-arrow-small-schwarz.svg";

import "./arrow.scss";

export const ArrowDown = (props) => {
  return <Arrow className={"arrow " + props.className} height={props.height} id={props.id} />;
};

export const ArrowUp = (props) => {
  return <Arrow className={"arrow arrow-up " + props.className} height={props.height} id={props.id} />;
};
