import { Component } from "react";

import { texts } from "./../translations";

import { CircleArrowLeft, CircleArrowRight } from "./../inserts/circle arrow/circleArrow";

var slides = [
  <img src={process.env.PUBLIC_URL + "/images/projects/Projekt1.jpg"} alt="1" id="Projekt1" />,
  <img src={process.env.PUBLIC_URL + "/images/projects/Projekt2.jpg"} alt="2" id="Projekt2" />,
  <img src={process.env.PUBLIC_URL + "/images/projects/Projekt3.jpg"} alt="3" id="Projekt3" />,
  <img src={process.env.PUBLIC_URL + "/images/projects/Projekt4.jpg"} alt="4" id="Projekt4" />,
];

var prevPos;

export default class References extends Component {
  constructor(props) {
    super(props);

    this.state = { gallery: false, gallery_main: 1 };

    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {}

  changeImage(e) {
    if (e.target.id === "next") {
      this.setState({ currRot: this.state.currRot - this.state.rotationOffset });
    } else {
      this.setState({ currRot: this.state.currRot + this.state.rotationOffset });
    }
  }

  toggleGallery() {
    this.setState({ gallery: !this.state.gallery });
  }

  changeMainImage(index) {
    let newImage = index;
    if (newImage < 0) {
      newImage = slides.length - 1;
    } else if (newImage > slides.length - 1) {
      newImage = 0;
    }
    this.setState({ gallery_main: newImage });
  }

  imageClass(id) {
    if (this.state.gallery_main === id) {
      return "active";
    } else if (this.state.gallery_main === id - 1 || (this.state.gallery_main === slides.length - 1 && id === 0)) {
      return "prev";
    } else if (this.state.gallery_main === id + 1 || (this.state.gallery_main === 0 && id === slides.length - 1)) {
      return "next";
    } else {
      return "inactive";
    }
  }

  swipe(event) {
    if (event.touches[0].clientX > prevPos + 80) {
      this.changeMainImage(this.state.gallery_main + 1);
      prevPos = event.touches[0].clientX;
    } else if (event.touches[0].clientX < prevPos - 80) {
      this.changeMainImage(this.state.gallery_main - 1);
      prevPos = event.touches[0].clientX;
    }
  }

  carouselImages() {
    let images = [];
    slides.forEach((slide, index) => {
      images.push(
        <>
          <div
            className={this.imageClass(index)}
            onClick={() => this.changeMainImage(index)}
            style={{ backgroundImage: `url('${slide.props.src}')` }}
            loading="lazy"
          />
          <label className={"image-description text-small " + this.imageClass(index)} htmlFor={index}>
            {texts.references["image" + index][this.props.language]}
          </label>
        </>
      );
    });
    return images;
  }

  render() {
    return (
      <div className="references container">
        <p className="title">{texts.references.title[this.props.language]}</p>
        <div className="divider"></div>
        <div className="col-12 p-0">
          <p className="text-medium text-center"> {texts.references.text_1[this.props.language]}</p>
        </div>
        <div className="col-lg-3"></div>
        <div className="col-lg-12 ">
          <div className="row">
            <CircleArrowLeft
              className="bi bi-next "
              onClick={() => {
                this.changeMainImage(this.state.gallery_main - 1);
              }}
              length="2rem"
            />

            <div
              className="gallery col-lg-12"
              onTouchMove={(touchEvent) => this.swipe(touchEvent)}
              onTouchStart={(touchEvent) => {
                prevPos = touchEvent.touches[0].clientX;
              }}
              onClick={() => this.toggleGallery()}
            >
              {this.carouselImages()}
            </div>
            <CircleArrowRight
              className="bi bi-prev "
              onClick={() => {
                this.changeMainImage(this.state.gallery_main + 1);
              }}
              length="2rem"
            />
          </div>
        </div>
        <div className={`popup-gallery ${this.state.gallery ? "active" : ""} `}>
          <div className="container">
            <i
              className="bi bi-x-circle gallery-control"
              onClick={() => {
                this.toggleGallery();
              }}
            />
            <div className="row">
              <div className="col-1">
                <CircleArrowLeft
                  className="bi bi-next "
                  onClick={() => {
                    this.changeMainImage(this.state.gallery_main - 1);
                  }}
                  length="2rem"
                />
              </div>
              <div className="col-10">
                <div className="main-image">{this.carouselImages()}</div>
                <div className="image-bar">
                  {slides.map((slide, index) => {
                    return (
                      <img
                        className={`image-preview ${this.state.gallery_main === index ? "active" : ""}`}
                        id={index}
                        alt=""
                        src={slide.props.src}
                        onClick={() => {
                          this.changeMainImage(index);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="col-1">
                <CircleArrowRight
                  className="bi bi-prev "
                  onClick={() => {
                    this.changeMainImage(this.state.gallery_main + 1);
                  }}
                  length="2rem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
