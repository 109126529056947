import { ReactComponent as SVG } from "./../images/icons/Blickfang_Icon-cross-black.svg";
import "./cross.scss";

export const CircleCross = (props) => {
  return (
    <div className={"icon " + props.className} onClick={props.onClick}>
      <SVG className={"circle-cross "} style={{ width: props.length, height: props.length, fill: props.color }} />
    </div>
  );
};
